import React from 'react';
import axios from 'axios';
import conf from "./../../../config.js";
import tokenApi from "../../auth/Helpers";
import Skeleton from 'react-loading-skeleton';
import { Link } from "react-router-dom";

export default class Profil extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            profileLoading: true,
            dataLoading: true,
            logo: '',
            cover: '',
        };
        this.logout = this.logout.bind(this)
    }

    componentDidMount() {
        // get session user
        if(localStorage.getItem('token') !== null)
        {
          axios.post(`${conf.uri_backend}api/v1/auth/sso/user`, {token:localStorage.getItem('token')})
          .then(res => {
            this.setState({
              data_session: res.data,
              // logo: res.data.profile.logo,
              website: res.data.profile.website,
              nama_institusi: res.data.profile.nama_institusi,
              profileLoading: false,
             }, function(){
                axios.get(`${conf.uri_backend}api/v1/select-institusi/`+this.state.data_session.access_to, {headers:{Authorization:tokenApi}}).then(res => {
                    this.setState({ 
                        institusi_id_select   : res.data.id,
                        jenjang_id_select     : res.data.m_struktur_jenjang_id,
                        npsn_select           : res.data.npsn,
                        alamat_select         : res.data.alamat_1,
                        logo                  : res.data.logo,
                        cover                 : (res.data.cover != null && res.data.cover != "null" ? res.data.cover : ''),
                        group_code_select     : res.data.group_code,
                        kode_pos_select       : res.data.kode_pos,
                        telp_institusi_select : res.data.telp_institusi,
                        email_select          : res.data.email,
                        jumlah_guru           : res.data.jumlah_guru,
                        jumlah_lainnya        : res.data.jumlah_lainnya,
                        jumlah_siswa          : res.data.jumlah_siswa,
                        fax_select            : res.data.fax,
                        dataLoading           : false,
                    }, function(){});
                });
            });
          }).catch(function(err){
            // console.log();
            if (err.response.status == 401)
            {
              window.location.replace(`${conf.uri_frontend}`);
              localStorage.setItem('token','');
              localStorage.setItem('is_login','');
            }  
          });
        }
        var element = document.getElementById("root");
        element.classList.add("profile-container");
    }

    componentWillUnmount(){
      var element = document.getElementById("root");
        element.classList.remove("profile-container");
    }

    logout(){
        this.setState({ isLoading: true });
        axios.post(`${conf.uri_backend}api/v1/auth/sso/logout`, {token:localStorage.getItem('token')}).then(res => {
            if(res.data.status == true){
                localStorage.setItem('token', '');
                localStorage.setItem('is_login', '');
                // window.location.replace(`${conf.uri_sso}`+'auth/logout?token=' + res.data.token + '&redirect_uri=' + `${conf.uri_frontend}auth/sso/redirect/`);
                window.location.replace(`${conf.uri_sso}`+'auth/logout-all?redirect_uri=' + `${conf.uri_frontend}auth/sso/redirect/`);
            }
        });
    }
    
    render () {
      return(
          <>
            {this.state.isLoading ? (
                    <div className="loader-box transparent-box flex-col"
                        style={{
                            position: 'absolute',
                            height: "100vh", 
                            width: "100%", 
                            zIndex: "99999",
                            top: '0',
                        }}
                    >
                        <div className="loader"></div>
                        <span className="text-white mt-5">Sedang Keluar...</span>
                    </div>
            ) : ("")}
            <div className="sm-container mt-6 mx-auto flex justify-center items-center" >
              <div className="flex flex-col rounded-lg shadow-xl px-4 py-4 profile-container" style={ this.state.cover == '' ?
                {
                  width: '79rem', 
                  height: '22rem', 
                  backgroundImage: 'url('+conf.uri_frontend+'/static/media/cover.b74c46fb.png)',
                  backgroundPosition: 'center',
                } : {
                  width: '79rem', 
                  height: '22rem', 
                  backgroundImage: 'url(https://static.schoolmedia.id/assets/socmed/NI/cover/'+this.state.cover+')',
                  backgroundPosition: 'center',
                }
              }>
                <div className="flex flex-row justify-end" style={{marginBottom: '12rem'}}>
                    <a href={"http://" + this.state.website} target="_blank" rel="noreferrer" className="bg-white flex items-center mr-2 px-6 py-2 rounded-full font-bold text-sm hover:shadow-inner">
                        Website
                        <i className="bx bx-right-top-arrow-circle text-medium text-lg ml-2 "></i>
                    </a>
                    <a href={"https://schoolmedia.id/profile/settings"} target="_blank" rel="noreferrer" className="bg-white flex items-center mr-2 px-6 py-2 rounded-full font-bold text-sm hover:shadow-inner">
                        Edit Profile
                        <i className="bx bx-edit-alt text-medium text-lg ml-2 "></i>
                    </a>
                    <button onClick={ this.logout} className="bg-danger flex items-center px-6 py-2 rounded-full font-bold text-sm text-white hover:shadow-xl border-2">
                        Keluar
                        <i className="bx bx-power-off text-medium text-lg ml-2"></i>
                    </button>
                </div>
                <div className="flex flex-row rounded-lg w-full bg-gray-200 hover:shadow-sm">
                  <div className="flex flex-col w-3/12 justify-center pb-5" style={{position: 'relative', bottom: '50px'}}>
                    {this.state.profileLoading ?
                    <>
                      <div className={`rounded-full border-5 mx-auto border-white shadow-xl animateFadeInBottom`}>
                        <Skeleton circle={true} height={170} width={170} className="bg-gray-400" />
                      </div>
                    </>:
                    <>
                      <div className={`rounded-full border-5 mx-auto border-white bg-white shadow-xl animateFadeInBottom`}>
                        <img
                          className="rounded-full border-5 mx-auto border-white shadow-xl"
                          style={{width: '12rem', height: '12rem', objectFit: 'cover'}}
                          src=
                            {(() => {
                              if (this.state.logo == "") {
                                return `${conf.uri_frontend}assets/img/user.jpeg`;
                              } else if (this.state.logo == null) {
                                return `${conf.uri_frontend}assets/img/user.jpeg`;
                              } else if (this.state.logo == "null") {
                                return `${conf.uri_frontend}assets/img/user.jpeg`;
                              } else {
                                return `${conf.uri_backend}storage/00-universal/${this.state.website}/logo/${this.state.logo}`;
                              }
                            })()}
                        />
                      </div>
                    </>
                    }
                    {this.state.dataLoading ?
                    <div className="flex flex-col mx-auto">
                      <Skeleton width={100} height={15} className="mt-4 bg-gray-400"/>
                      <Skeleton width={100} height={15} className="mt-2 bg-gray-400"/>
                    </div>:
                    <>
                      <h1 className="text-xl font-bold text-gray-800 mt-4 mx-auto">{this.state.nama_institusi}</h1>
                    </>
                    }
                  </div>
                  <div className="flex flex-col w-9/12 pr-3">
                        <div className="flex flex-row w-full" style={{position: 'relative', bottom: '30px'}}>
                            {this.state.dataLoading ?
                              <>
                                <div className="w-4/12 flex flex-col items-center justify-center bg-white rounded-lg hover:shadow-xl px-3 py-3 mx-2 animateFadeInBottom">
                                  <div className="flex flex-col items-center justify-center">
                                      <Skeleton circle={true} height={40} width={40} className="bg-gray-400"/>
                                      <Skeleton width={200} height={15} className="mt-2 bg-gray-400"/>
                                  </div>
                                </div>
                                <div className="w-4/12 flex flex-col items-center justify-center bg-white rounded-lg hover:shadow-xl px-3 py-3 mx-2 animateFadeInBottom">
                                  <div className="flex flex-col items-center justify-center">
                                      <Skeleton circle={true} height={40} width={40} className="bg-gray-400"/>
                                      <Skeleton width={200} height={15} className="mt-2 bg-gray-400"/>
                                  </div>
                                </div>
                                <div className="w-4/12 flex flex-col items-center justify-center bg-white rounded-lg hover:shadow-xl px-3 py-3 mx-2 animateFadeInBottom">
                                  <div className="flex flex-col items-center justify-center">
                                      <Skeleton circle={true} height={40} width={40} className="bg-gray-400"/>
                                      <Skeleton width={200} height={15} className="mt-2 bg-gray-400"/>
                                  </div>
                                </div>
                              </> :
                              <>
                                <div className="w-4/12 flex flex-col items-center justify-center bg-white rounded-lg hover:shadow-xl px-3 py-3 mx-2 border">
                                    <h1 className="font-medium text-gray-800" style={{fontSize: '2.2rem'}}>{this.state.jumlah_guru !== '' ? this.state.jumlah_guru : '-' }</h1>
                                    <p className="font-bold text-gray-600" style={{fontSize: 'o.7rem'}}>Jumlah Guru</p>
                                </div>
                                <div className="w-4/12 flex flex-col items-center justify-center bg-white rounded-lg hover:shadow-xl px-3 py-3 mx-2 border">
                                    <h1 className="font-medium text-gray-800" style={{fontSize: '2.2rem'}}>{this.state.jumlah_siswa !== '' ? this.state.jumlah_siswa : '-' }</h1>
                                    <p className="font-bold text-gray-600" style={{fontSize: 'o.7rem'}}>Jumlah Siswa</p>
                                </div>
                                <div className="w-4/12 flex flex-col items-center justify-center bg-white rounded-lg hover:shadow-xl px-3 py-3 mx-2 border">
                                    <h1 className="font-medium text-gray-800" style={{fontSize: '2.2rem'}}>{this.state.jumlah_lainnya !== '' ? this.state.jumlah_lainnya : '-' }</h1>
                                    <p className="font-bold text-gray-600" style={{fontSize: 'o.7rem'}}>Jumlah PTK/Lainnya</p>
                                </div>
                              </>
                            }
                        </div>
                        <div className="flex flex-row bg-white rounded-lg hover:shadow-xl mr-2 ml-2 border">
                          {this.state.dataLoading ?
                          <>
                            <div className="w-4/12 flex text-left flex-col px-5 py-4 mx-2 animateFadeInBottom">
                              <div className="flex flex-col items-center justify-center">
                                <Skeleton width={200} height={15} count={2}  className="mt-2 bg-gray-400"/>
                              </div>
                            </div>
                            <div className="w-4/12 flex text-left flex-col px-5 py-4 mx-2 animateFadeInBottom">
                              <div className="flex flex-col items-center justify-center">
                                <Skeleton width={200} height={15} count={2}  className="mt-2 bg-gray-400"/>
                              </div>
                            </div>
                            <div className="w-4/12 flex text-left flex-col px-5 py-4 mx-2 animateFadeInBottom">
                              <div className="flex flex-col items-center justify-center">
                                <Skeleton width={200} height={15} count={2}  className="mt-2 bg-gray-400"/>
                              </div>
                            </div>
                          </>:
                          <>
                            <div className="w-4/12 flex text-left flex-col px-5 py-4 mx-2">
                                <div className="mb-2">
                                    <p className="text-xs text-gray-600">NPSN :</p>
                                    <h1 className="text-md text-gray-800">{this.state.npsn_select !== null ? this.state.npsn_select : '-' }</h1>
                                </div>
                                <div className="">
                                    <p className="text-xs text-gray-600">Email :</p>
                                    <h1 className="text-md text-gray-800">{this.state.email_select !== null ? this.state.email_select : '-' }</h1>
                                </div>
                            </div>
                            <div className="w-4/12 flex text-left flex-col px-5 py-4 mx-2">
                                <div className="mb-2">
                                    <p className="text-xs text-gray-600">Alamat :</p>
                                    <h1 className="text-md text-gray-800">{this.state.alamat_select !== null ? this.state.alamat_select : '-' }</h1>
                                </div>
                                <div className="">
                                    <p className="text-xs text-gray-600">Kode Pos :</p>
                                    <h1 className="text-md text-gray-800">{this.state.kode_pos_select !== null ? this.state.kode_pos_select : '-' }</h1>
                                </div>
                            </div>
                            <div className="w-4/12 flex text-left flex-col px-5 py-4 mx-2">
                                <div className="mb-2">
                                    <p className="text-xs text-gray-600">Nomor Telepon :</p>
                                    <h1 className="text-md text-gray-800">{this.state.telp_institusi_select !== null ? this.state.telp_institusi_select : '-' }</h1>
                                </div>
                                <div className="">
                                    <p className="text-xs text-gray-600">Fax :</p>
                                    <h1 className="text-md text-gray-800">{this.state.fax_select !== null ? this.state.fax_select : '-' }</h1>
                                </div>
                            </div>
                          </>
                          }
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </>
      )
    }
}